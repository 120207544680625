<template>
    <div class="section">
        <client-period-filters></client-period-filters>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card class="mb-3">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h5>{{ $t('client.pages.costs.barChart.card.labels.title') }}</h5>
                        </div>
                        <div v-if="!refreshData">
                            <h5>{{ $t('client.pages.costs.barChart.card.labels.total') }} {{ $n(data.total, 'currency') }}</h5>
                        </div>
                    </div>
                    <spinner v-if="refreshData" />
                    <bar-chart
                        :data="data.items.map(a => a.amount)"
                        :labels="data.items.map(a => a.skill_name)"
                        :page="barChartPages.DASHBOARD.COSTS"
                        background-color="rgba(228, 102, 81, 0.6)"
                        border-color="#e46651"
                        v-if="!refreshData && data.items.length > 0"
                    ></bar-chart>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :title="$t('client.pages.costs.doughnutChart.card.labels.title')" title-tag="h5" class="mb-3">
                    <spinner v-if="refreshData"></spinner>
                    <doughnut-chart
                        :data="data.items.map(a => a.percentage)"
                        :labels="data.items.map(a => a.skill_name)"
                        :page="barChartPages.DASHBOARD.COSTS"
                        v-if="!refreshData && data.items.length > 0"
                    ></doughnut-chart>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import ClientPeriodFilters from '@/components/client/dashboard/ClientPeriodFilters';
import BarChart from '@/components/shared/chart/BarChart';
import DoughnutChart from '@/components/shared/chart/DoughnutChart';
import InputFilterEvents from '@/views/client/InputFilterEvents';
import { EVENTS } from '@/constants/client/events';
import { DashboardRepository } from '@/repositories';
import { CHART } from '@/constants/client/chart';
import Spinner from '@/components/shared/element/Spinner';

export default {
    name: 'ClientDashboardCosts',
    extends: InputFilterEvents,
    components: {
        ClientPeriodFilters,
        BarChart,
        DoughnutChart,
        Spinner
    },
    data: () => ({
        data: null,
        refreshData: true,
        barChartPages: CHART
    }),
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_AND_PERIOD_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            DashboardRepository.getCostsBySkill(this.getFilters())
                .then(response => {
                    this.data = response.data.data;
                    this.refreshData = false;
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
